<template>
  <span
    class="avatar"
    :class="[size, { round: round, 'p-overlay-badge': notifications, border: border }]"
    v-tooltip.bottom="{ value: fullName, disabled: !tooltip }"
  >
    <img :src="loadedSrc || src" :alt="fullName" v-if="loadedSrc || src" />
    <span class="initials" v-else-if="initials" :style="{ background: textToColor() }">
      {{ initials }}
    </span>
    <i v-else-if="showLoader" class="pi pi-spin pi-spinner"></i>
    <span v-if="notifications" class="p-badge p-badge-danger">
      {{ notifications }}
    </span>
  </span>
</template>

<script>
import { useUserStore } from "@/stores/user";
import ColorHash from "color-hash";
import Tooltip from "primevue/tooltip";

export default {
  name: "Avatar",
  directives: {
    tooltip: Tooltip,
  },
  props: {
    name: String,
    firstName: String,
    lastName: String,
    src: String,
    loadImage: String,
    userName: String,
    notifications: Number,
    size: {
      type: String,
      default: "medium",
    },
    round: {
      type: Boolean,
      default: true,
    },
    border: Boolean,
    tooltip: Boolean,
    showLoader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadedSrc: null,
      imageEnabled: false,
    };
  },
  setup: () => {
    return { userStore: useUserStore() };
  },
  computed: {
    first() {
      return (
        this.firstName ||
        (this.name && this.name.replaceAll("-", " ").replace("The ", "").split(" ")[0])
      );
    },
    last() {
      const nameParts =
        this.name &&
        this.name
          .replaceAll("-", " ")
          .replace(" Ltd", "")
          .replace(/ *\([^)]*\) */g, "")
          .split(" ");

      return (
        this.lastName ||
        (nameParts &&
          nameParts.length > 1 && // check that there are at least 2 name parts
          (nameParts[nameParts.length - 1].length > 2 // check that the last name part has at least 3 chars
            ? nameParts[nameParts.length - 1]
            : nameParts[nameParts.length - 2])) ||
        " "
      );
    },
    fullName() {
      return this.name || `${this.first} ${this.last}`;
    },
    initials() {
      if (this.name && this.name.toUpperCase().startsWith("GENTWO")) {
        return "G2";
      }
      if (this.name && this.name.startsWith("group::")) {
        const actualName = this.name.replace("group::", "");
        return actualName.charAt(0);
      }
      return (
        this.first &&
        this.last &&
        (this.first.charAt(0) + this.last.charAt(0)).replace(/[^a-zA-Z0-9]/g, "").toUpperCase()
      );
    },
  },
  async created() {
    if (this.imageEnabled && (this.loadImage || this.userName)) {
      this.loadedSrc = await this.getUserImage(this.loadImage || this.userName);
    }
  },
  methods: {
    randomColor() {
      return this.initials ? "hsl(" + Math.random() * 360 + ", 82%, 40%)" : "transparent";
    },
    textToColor() {
      var colorHash = new ColorHash({ saturation: "82%", lightness: "40%" });
      return this.initials ? `hsl(${colorHash.hsl(this.fullName).join()})` : "transparent";
    },
    async getUserImage(userName) {
      try {
        if (userName) {
          const imageSrc = await this.userStore.getUserImage(userName);
          return imageSrc;
        } else {
          return null;
        }
      } catch (ex) {
        return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  font-size: 0.666667em;
  font-family: var(--font-family);

  &.large {
    font-size: 1.5em;
  }
  &.xlarge {
    font-size: 2em;
  }
  &.xxlarge {
    font-size: 3em;
  }
  &.round {
    img,
    .initials {
      border-radius: 50%;
    }
  }
  &.border {
    img,
    .initials {
      box-sizing: content-box;
      border: 2px solid var(--hover-button-bg);
    }
  }
  img,
  .initials {
    border-radius: 5px;
  }
  img {
    display: inline-block;
    object-fit: cover;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: bottom;
  }
  .initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    color: var(--surface-a);
  }
}
.p-overlay-badge {
  display: inline-block;

  .p-badge {
    top: 20%;
    right: 20%;
    padding: 0;
  }
}
</style>
