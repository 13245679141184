import axios from "axios";
import buildNumber from "../version.json";

export const reload = {
  data() {
    return {
      currentHash: buildNumber,
      hashChanged: false,
      newHash: "",
      reloadTimer: null,
    };
  },
  methods: {
    initVersionCheck(frequency = 1000 * 10) {
      this.reloadTimer = setInterval(() => {
        this.checkVersion();
      }, frequency);
    },
    async checkVersion() {
      try {
        const fileResponse = await axios.get("/version.json");

        this.newHash = fileResponse.data;

        this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);
        if (this.hashChanged) {
          clearInterval(this.reloadTimer);
        }
      } catch (error) {
        this.loading = false;
        if (error.response?.data?.message) {
          this.errorStatus = error.response.data.message;
        } else {
          this.errorStatus = "Error: Network Error";
        }
      }
    },
    hasHashChanged(currentHash, newHash) {
      if (currentHash == "" || newHash == "") {
        return false;
      }
      return currentHash !== newHash && currentHash < newHash;
    },
    reloadApp() {
      setTimeout(function () {
        window.location.reload();
      }, 100);
    },
  },
};
