<template>
  <div class="login p-d-flex p-flex-column p-flex-lg-row p-ai-center p-jc-between">
    <div class="hero p-relative">
      <icon data="@icon/auth-leaf.svg" original class="leaf-icon" />
      <div class="p-d-flex p-relative hero-content">
        <router-link to="/">
          <icon data="@icon/logo-text.svg" original width="12rem" height="100%"></icon>
        </router-link>
        <div class="hero-image"></div>
        <!-- <img class="p-width-100 p-hidden" :src="require('@/assets/images/g2pro.png')" /> -->
        <h1 class="p-mb-0 hero-title">
          Your online Portal to transforming Assets into Opportunities
        </h1>
      </div>
    </div>
    <div
      class="p-col-12 p-sm-8 p-md-6 p-lg-7 p-d-flex p-flex-column p-jc-between p-align-center p-height-100 hero-right"
    >
      <Card class="login-card p-d-flex p-flex-1 p-jc-center p-align-center">
        <template #content>
          <h2 class="p-mt-0">Login to PRO</h2>
          <h6 class="p-mb-6 p-text-secondary p-mt-0 p-text-light">
            Let's get started with your asset journey!
          </h6>
          <form
            id="login-form"
            name="login-form"
            class="login-form p-input-filled"
            @submit.prevent="handleLogin"
            novalidate
            ref="loginForm"
          >
            <h4 class="header p-error" v-if="backendError">{{ backendError }}</h4>

            <div class="p-fluid p-mb-6">
              <div class="p-field p-grid small-gutter">
                <div class="p-col-12">
                  <InputText
                    id="email"
                    name="email"
                    type="text"
                    v-model.trim="email"
                    placeholder="Your e-mail"
                    :class="{ 'p-invalid': v$.email.$error, 'p-filled': autofilled }"
                    :disabled="submitting"
                    autocomplete="email"
                    @animationstart="handleAutoFill"
                  />
                  <small id="email-help" class="p-error" v-if="v$.email.$error">
                    {{ v$.email.$errors[0].$message }}
                  </small>
                </div>
              </div>
              <div class="p-field p-grid small-gutter" v-if="!canMicrosoftLogin">
                <div class="p-col-12">
                  <Password
                    id="password"
                    name="password"
                    v-model="password"
                    placeholder="Your password"
                    :feedback="false"
                    :inputClass="passwordInputClass"
                    :disabled="submitting"
                    autocomplete="current-password"
                  />
                  <small id="password-help" class="p-error" v-if="v$.password.$error">
                    {{ v$.password.$errors[0].$message }}
                  </small>
                </div>
              </div>
            </div>
            <Button
              outlined
              class="p-width-100 p-mb-6"
              :disabled="!autofilled && submitting"
              @click="loginMicrosoftRedirect"
              type="submit"
              v-if="canMicrosoftLogin"
            >
              <icon
                data="@icon/microsoft.svg"
                width="0.85em"
                height="0.85em"
                original
                class="p-mr-2"
              />
              <span class="p-button-label">Login with Microsoft</span>
            </Button>
            <Button
              label="Login"
              class="p-width-100 p-mb-6"
              type="submit"
              :outlined="!autofilled && (v$.$invalid || submitting)"
              :disabled="!autofilled && (v$.$invalid || submitting)"
              :loading="submitting"
              iconPos="right"
              v-else
            />
            <router-link :to="{ name: 'ForgotPassword' }" v-slot="{ navigate }" custom>
              <Button
                class="button-text p-button-link p-text-uppercase p-p-0"
                iconPos="right"
                icon="pi pi-arrow-right"
                label="I forgot my password"
                @click="navigate"
              />
            </router-link>
          </form>
        </template>
      </Card>
      <p>{{ copyrightYear }} GenTwo. All right Reserved.</p>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { useVuelidate } from "@vuelidate/core";
import { useAccountStore } from "@/stores/account";
import { required, helpers } from "@vuelidate/validators";
import "detect-autofill";

export default {
  name: "Login",
  title: "Login",
  components: {
    Card,
    Button,
    InputText,
    Password,
  },
  setup() {
    return { v$: useVuelidate(), accountStore: useAccountStore() };
  },
  data() {
    return {
      email: "",
      password: "",
      rememberMe: true,
      submitting: false,
      autofilled: false,
      backendError: null,
      successMessage: null,
      copyrightYear: new Date().getUTCFullYear(),
    };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("This field cannot be empty", required),
        $autoDirty: true,
      },
      password: {
        required: helpers.withMessage("This field cannot be empty", required),
        $autoDirty: true,
      },
    };
  },
  computed: {
    userLogged() {
      return this.accountStore.userLogged;
    },
    canMicrosoftLogin() {
      return this.email.endsWith("@g2fp.com") || this.email.endsWith("@gentwo.com");
    },
    finishExternalLogin() {
      return this.$route.query.finishLogin;
    },
    redirect() {
      return this.$route.query.redirect;
    },
    passwordInputClass() {
      return [
        ...(this.v$.password.$error ? ["p-invalid"] : []),
        ...(this.autofilled ? ["p-filled"] : []),
      ].join(", ");
    },
  },
  async created() {
    if (this.finishExternalLogin || this.userLogged) {
      const response = await this.accountStore.GetLogin();
      if (response && response.status) {
        switch (response.status) {
          case 200:
            this.redirectAfterSuccessfulLogin();
            break;
          case 400:
            this.backendError = response.data.message;
            break;
        }
      }
    }
  },
  methods: {
    handleAutoFill(e) {
      if (e.animationName === "onautofillstart") {
        this.autofilled = true;
      }
    },
    async handleLogin() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.submitting = true;
      this.backendError = null;

      try {
        const result = await this.accountStore.login({
          userName: this.email,
          password: this.password,
          rememberMe: this.rememberMe,
        });

        const response = result?.status ? result : result?.response;

        if (response && response.status) {
          switch (response.status) {
            case 200:
              this.redirectAfterSuccessfulLogin();
              break;
            case 400:
              this.backendError = response.data.detail;
              break;
          }
        }
      } finally {
        this.submitting = false;
      }
    },
    loginMicrosoftRedirect() {
      let redirectUri = encodeURIComponent(
        window.location.origin + "/apptwo/account/LoginMicrosoftCallback",
      );
      let returnUrl = new URL(window.location);
      returnUrl.searchParams.append("finishLogin", true);
      let encodedReturnUrl = encodeURIComponent(returnUrl.toString());
      window.location = `/apptwo/account/loginmicrosoft?redirectUri=${redirectUri}&returnUrl=${encodedReturnUrl}`;
    },
    redirectAfterSuccessfulLogin() {
      if (this.redirect) {
        window.location = this.redirect;
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.login {
  padding: 0;
  height: 100vh;
  background-color: var(--login-bg);
  margin: -1rem -2rem;
  @media (max-width: 768px) {
    height: 100%;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .hero {
    height: 100%;
    width: 100%;
    background-color: var(--login-sidebar-bg);

    @media (max-width: 768px) {
      background-color: transparent;
      height: 30%;
    }
    h1 {
      font-family: var(--font-family);
      font-size: 3.5rem;
      line-height: 1.1;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    h2 {
      font-family: var(--font-family);
      font-size: 3.43rem;
      font-weight: 600;
      margin: 0.1rem 0;
    }

    h3 {
      font-size: 2.285rem;
      font-weight: normal;
      line-height: 1.2;
      color: rgba(248, 248, 243, 0.65);
      margin: 0.1rem 0 1rem;
    }

    .play-button {
      width: 4rem !important;
      height: 4rem !important;
      background: #ffffff;
      color: rgba(21, 28, 31, 0.65);

      &:hover,
      &:focus {
        background: #dddddd;
      }
    }

    p {
      color: var(--off-white-65);
    }
    .leaf-icon {
      height: 70%;
      width: 50%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      @media (max-width: 768px) {
        height: 100%;
      }
    }
    &-content {
      z-index: 2;
      flex-direction: column;
      padding: 3rem;
      justify-content: space-between;
      height: 100%;
      @media (max-width: 768px) {
        padding: 1.5rem;
      }
    }
    &-title {
      @media (max-width: 768px) {
        display: none;
      }
    }
    img {
      @media (max-width: 768px) {
        display: none;
      }
    }
    &-right {
      p {
        color: var(--secondary-table-text-color);
      }
      @media (max-width: 768px) {
        flex: 1;
      }
    }
    &-image {
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @media (max-width: 768px) {
        display: none;
      }
      @media (prefers-color-scheme: light) {
        background-image: url("@/assets/images/g2pro-light.png");
      }
      @media (prefers-color-scheme: dark) {
        background-image: url("@/assets/images/g2pro-dark.png");
      }
    }
  }
}

.logo {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.login-form {
  .header {
    margin: 0 0 0.75rem;
  }

  .p-field {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    & > label {
      color: var(--secondary-color-lighter-alpha);
    }
  }

  .p-field-checkbox {
    margin-bottom: 0;
  }
}
:deep() > {
  .login-card {
    .p-card-body {
      width: 30rem;
      padding: 0;
      margin: 0 auto;
    }
  }
}

.login-card {
  background: transparent;
  border-radius: var(--border-radius-xl);
  @media (max-width: 768px) {
    flex: 1;
  }
  :deep() {
    .p-card-content {
      padding: 0;
    }
  }
}
</style>
