<template>
  <div class="p-d-flex p-ai-center p-jc-around bg-off-white p-py-6 p-my-auto">
    <div class="p-text-center">
      <h1>Access denied!</h1>
      <div class="p-my-5">
        <p class="font-light">You do not have enough rights to access GenTwo Pro.</p>
        <LockIcon></LockIcon>
        <h3>Please go to GenTwo to access GenTwo Plus.</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { useApiStore } from "@/stores/api";
import LockIcon from "@/components/svg/LockIcon";
import LinkButton from "@/components/elements/LinkButton";
export default {
  name: "AccessDenied",
  components: { LinkButton, LockIcon },
  title: "Access Denied",
  setup: () => {
    return { apiStore: useApiStore() };
  },
  computed: {
    options() {
      return this.apiStore.options;
    },
  },
};
</script>

<style lang="scss">
h1 {
  font-family: var(--font-family-serif);
  font-size: 38px;
}
.bg-off-white {
  background-color: var(--off-white-100);
}
.font-light {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 32px;
}
</style>
