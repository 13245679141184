<template>
  <Dialog
    v-model:visible="visible"
    :closeOnEscape="true"
    :closable="true"
    style="max-width: 700px"
    :contentStyle="{ overflowY: 'auto' }"
    :modal="true"
    @hide="onHide"
    class="cutoff-popup"
    v-if="enabled"
  >
    <template #header>
      <img src="/img/cut-off-times-intro.svg" width="686" height="215" class="header-bg" />
      <h4 class="p-dialog-title">Cut-off Time for Payments - December 2022</h4>
    </template>

    <div>
      <p>Dear Client,</p>
      <p>
        Please note - Payments that you wish to have executed by the end of 2022, must be received
        by Paying Agent no later than <strong>30/12/2022, 09:00 AM CET</strong>. This means that
        Payment Instruction workflows must be initiated several days before 30/12/2022, for the
        Directors (December 26th and 27th, 2022 are public holidays in Guernsey) of the Issuer to
        have sufficient time to approve Payment Instructions.
      </p>
      <p>
        Payment Instruction workflows that do not meet the above deadline will be executed by Paying
        Agent on a best-efforts basis only.
      </p>
      <p>All the best,</p>
      <p>GenTwo</p>
    </div>
    <template #footer>
      <Button outlined label="Close" @click="visible = false" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import { useCookies } from "vue3-cookies";

export default {
  name: "CutoffPopup",
  components: { Dialog, Button },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    enabled: Boolean,
  },
  data() {
    return {
      visible: false,
      cookie_name: "featureCookie_20221214_cutoff_times",
    };
  },
  created() {
    const cookie = this.cookies.get(this.cookie_name);
    if (!cookie) {
      this.visible = true;
    }
  },
  methods: {
    onHide() {
      this.cookies.set(this.cookie_name, true, "1d");
    },
  },
};
</script>

<style scoped lang="scss">
.p-dialog-title {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}
.header-bg {
  width: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}
</style>
