<template>
  <svg width="68" height="89" viewBox="0 0 68 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.0003 69.9116C32.311 69.9116 30.6596 69.4107 29.255 68.4722C27.8504 67.5337 26.7556 66.1997 26.1092 64.639C25.4627 63.0783 25.2935 61.3609 25.6231 59.7041C25.9527 58.0472 26.7661 56.5253 27.9607 55.3308C29.1552 54.1363 30.6771 53.3228 32.3339 52.9932C33.9908 52.6637 35.7081 52.8328 37.2689 53.4793C38.8296 54.1257 40.1635 55.2205 41.1021 56.6251C42.0406 58.0297 42.5415 59.6811 42.5415 61.3704C42.5395 63.635 41.639 65.8064 40.0376 67.4077C38.4362 69.0091 36.2649 69.9096 34.0003 69.9116ZM34.0003 55.9836C32.9345 55.9836 31.8926 56.2997 31.0065 56.8918C30.1203 57.4839 29.4296 58.3255 29.0218 59.3101C28.6139 60.2948 28.5072 61.3783 28.7151 62.4236C28.923 63.4689 29.4363 64.429 30.1899 65.1827C30.9435 65.9363 31.9037 66.4495 32.949 66.6574C33.9943 66.8653 35.0778 66.7586 36.0624 66.3508C37.0471 65.9429 37.8887 65.2522 38.4808 64.3661C39.0729 63.4799 39.3889 62.4381 39.3889 61.3723C39.3872 59.9437 38.8188 58.5741 37.8087 57.5639C36.7985 56.5537 35.4289 55.9854 34.0003 55.9836Z"
      fill="url(#paint0_linear_12568_21277)"
    />
    <path
      d="M65.0029 39.0208C62.6881 36.1945 58.8359 33.9498 54.9146 33.9498H54.4702V20.6715C54.4655 9.38648 45.285 0.206055 34 0.206055C28.5724 0.206818 23.3672 2.36321 19.5292 6.20103C15.6912 10.0389 13.5346 15.2439 13.5336 20.6715V33.9498H13.0902C6.14779 33.9498 0.5 39.5976 0.5 46.54V76.2041C0.5 83.1466 6.14779 88.7943 13.0902 88.7943H54.9146C58.2516 88.789 61.4504 87.4607 63.8096 85.1006C66.1688 82.7405 67.4959 79.5412 67.5 76.2041V46.54C67.503 43.8295 66.6268 41.1911 65.0029 39.0208ZM16.6843 20.6715C16.6843 16.0791 18.5086 11.6748 21.7559 8.42743C25.0033 5.1801 29.4076 3.35577 34 3.35577C43.548 3.35577 51.3157 11.1235 51.3157 20.6715V33.9498H16.6843V20.6715ZM64.3503 76.2041C64.3472 78.7061 63.3523 81.1049 61.5836 82.8745C59.8148 84.6442 57.4166 85.6403 54.9146 85.6446H13.0902C10.5874 85.6416 8.18791 84.646 6.41814 82.8762C4.64836 81.1064 3.65276 78.707 3.64971 76.2041V46.54C3.65251 44.0371 4.64802 41.6375 6.41786 39.8677C8.18769 38.0978 10.5873 37.1023 13.0902 37.0995H54.9146C56.8512 37.0973 58.7411 37.694 60.3253 38.8078C60.6374 39.0271 60.9361 39.2649 61.2198 39.5199C62.2068 40.4036 62.9962 41.4857 63.5365 42.6954C64.0767 43.9051 64.3557 45.2152 64.3551 46.54L64.3503 76.2041Z"
      fill="url(#paint1_linear_12568_21277)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_12568_21277"
        x1="34.0003"
        y1="52.8291"
        x2="34.0003"
        y2="69.9116"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#25464F" />
        <stop offset="1" stop-color="#25464F" stop-opacity="0.65" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_12568_21277"
        x1="34"
        y1="0.206055"
        x2="34"
        y2="88.7943"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#25464F" />
        <stop offset="1" stop-color="#25464F" stop-opacity="0.65" />
      </linearGradient>
    </defs>
  </svg>
</template>
