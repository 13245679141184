<template>
  <Dialog
    v-model:visible="visible"
    :closeOnEscape="true"
    :closable="true"
    :style="{ width: '100%', 'max-width': '740px' }"
    :modal="true"
    @hide="onHide"
    class="feature-popup"
    v-if="enabled && latestReleaseData?.showPopup"
  >
    <template #closeicon>
      <i
        class="pi pi-times-circle"
        style="font-size: 1.55rem; cursor: pointer; color: var(--primary-color)"
      ></i>
    </template>
    <template #header>
      <h2 class="p-dialog-title p-pl-2">{{ latestReleaseData?.headerTitle }}</h2>
      <div class="p-border-gradient p-width-100 p-mt-5"></div>
    </template>
    <div class="p-d-flex p-flex-column p-width-100">
      <Carousel
        :value="releasePopUpImage"
        :swipeThreshold="20"
        :numScroll="1"
        :numVisible="1"
        content-class="p-relative"
        :showNavigators="releasePopUpImage.length > 1"
        :showIndicators="releasePopUpImage.length > 1"
        v-if="releasePopUpImage"
      >
        <template #item="slotProps">
          <img
            :src="hasProperImageFormat(slotProps.data)"
            :alt="slotProps.data?.name"
            class="p-width-100"
          />
        </template>
        <template #previousicon>
          <icon data="@icon/arrow-circle-left.svg" :fill="true" class="icon-circle-left" />
        </template>
        <template #nexticon>
          <icon data="@icon/arrow-circle-right.svg" :fill="true" class="icon-circle-right"
        /></template>
      </Carousel>
      <div class="paragraph_wrapper">
        <p class="paragraph_text p-mb-4">
          {{ latestReleaseData?.contentTitle }}
        </p>
        <p
          class="paragraph_text__small"
          v-dompurify-html="latestReleaseData?.contentDescription"
        ></p>
        <a :href="hasReleaseNotesWebUrl" class="element-link" target="_blank">
          {{ latestReleaseData?.releaseNotesLink[0]?.releaseLinkText }}
        </a>
        <div class="line-divider"></div>
      </div>
    </div>

    <template #footer>
      <div class="p-d-flex p-jc-between">
        <div class="srf-form p-d-flex p-input-filled">
          <Checkbox :binary="true" id="hideNextTime" name="hideNextTime" v-model="hideNextTime">
            Don't show next time
          </Checkbox>
        </div>
        <Button label="Close" @click="visible = false" class="p-button-outlined" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useCookies } from "vue3-cookies";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Carousel from "primevue/carousel";
import Checkbox from "@/components/elements/Checkbox";
import LinkButton from "@/components/elements/LinkButton";
import { useApiStore } from "@/stores/api";

export default {
  name: "FeaturePopup",
  components: { Dialog, Button, Checkbox, Carousel, LinkButton },
  setup() {
    const apiStore = useApiStore();
    const { cookies } = useCookies();

    return { cookies, apiStore };
  },
  props: {
    enabled: Boolean,
  },
  data() {
    return {
      visible: false,
      hideNextTime: false,
      currentPopupDate: null,
      lastSeenPopupDate: this.cookies.get("featureCookie_date"),
      latestReleaseData: null,
      releasePopUpImage: null,
      releaseNotesBaseUrl: "https://www.gentwo.com/communications/",
    };
  },
  async mounted() {
    try {
      const response = await this.apiStore.loadReleaseNotesPopup();

      if (response) {
        this.latestReleaseData = response.data?.attributes;
        this.releasePopUpImage = this.latestReleaseData?.releaseNoteImage?.data?.map(
          el => el?.attributes,
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    this.currentPopupDate = this.latestReleaseData?.currentPopupDate;
    if (this.lastSeenPopupDate !== this.currentPopupDate) {
      this.visible = true;
    }
  },
  computed: {
    strapiBaseUrl() {
      return import.meta.env.VITE_STRAPI_BASE_URL;
    },
    hasReleaseNotesWebUrl() {
      return (
        this.latestReleaseData?.releaseNotesLink[0]?.release_note?.data?.attributes?.slug &&
        this.releaseNotesBaseUrl +
          this.latestReleaseData?.releaseNotesLink[0]?.release_note?.data?.attributes?.slug
      );
    },
  },
  methods: {
    onHide() {
      const hideNextTime = this.hideNextTime;
      if (hideNextTime) {
        const expirationDate = new Date(this.currentPopupDate);
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        this.cookies.set("featureCookie_date", this.currentPopupDate, expirationDate);
      }
    },
    hasProperImageFormat(img) {
      const imgUrl = img.formats?.medium?.url || img.url;
      return this.strapiBaseUrl + imgUrl;
    },
  },
};
</script>

<style scoped lang="scss">
.p-dialog-title {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  line-height: 2rem;
}

.icon-circle-left {
  position: absolute;
  left: 0;
  z-index: 10;
  width: 32px !important;
  height: 32px !important;
  background-color: var(--checkbox-background);
  color: var(--surface-a);
}

.icon-circle-right {
  position: absolute;
  right: 0;
  z-index: 10;
  width: 32px !important;
  height: 32px !important;
  background-color: var(--checkbox-background);
  color: var(--surface-a);
}

.header-bg {
  width: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}
.paragraph_wrapper {
  .paragraph_text {
    font-size: 2.2rem;
    font-weight: 600;
    color: var(--primary-color);
    &__small {
      color: var(--primary-color);
      font-size: 1.5rem;
      margin: 0;
    }
  }
  .element-link {
    font-size: 1.17rem;
    margin: 0.8rem;
  }
  .line-divider {
    border: 1px solid var(--table-label-color);
    margin: 2rem 0;
  }
}

:deep() {
  .p-carousel {
    .p-carousel-item {
      img {
        aspect-ratio: 16/9;
        height: auto;
        object-fit: cover;
      }
    }
    .p-carousel-next-button {
      position: absolute;
      right: 0;
      z-index: 10;
      width: 32px !important;
      height: 32px !important;
      .p-icon {
        background-color: var(--checkbox-background);
        color: var(--primary-color);
        padding: 3px 0px 2px 4px;
        width: 100%;
        height: 100%;
      }
    }
    .p-carousel-prev-button {
      position: absolute;
      left: 0;
      z-index: 10;
      width: 32px !important;
      height: 32px !important;

      .p-icon {
        background-color: var(--checkbox-background);
        color: var(--primary-color);
        padding: 3px 3px 1px 0px;
        width: 100%;
        height: 100%;
      }
    }
    .p-carousel-indicator-list {
      padding: 0;
      .p-carousel-indicator {
        list-style-type: none;

        button {
          width: 7px !important;
          height: 7px !important;
          border-radius: 7px !important;
          background-color: var(--card-tag-inverted-bg);
        }
      }
    }
  }
}
</style>
