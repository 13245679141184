import { createPinia, defineStore, PiniaPluginContext } from "pinia";
import { PiniaDebounce } from "@pinia/plugin-debounce";
import pDebounce from "p-debounce";
import { useApiStore } from "./api";
import { useAccountStore } from "./account";

//const debug = process.env.NODE_ENV !== "production";

export const pinia = createPinia();

pinia
  .use(({ app, store }: PiniaPluginContext) => {
    store.apiClient = app.config.globalProperties.$apiClient;
  })
  .use(PiniaDebounce(pDebounce));

export const useRootStore = defineStore("RootStore", {
  actions: {
    async init(vue) {
      const api = useApiStore();
      const account = useAccountStore();
      api.init(vue);
      account.init();
    },
  },
});
