<template>
  <div class="p-fixed p-right-0 p-bottom-0 p-mb-5 p-mr-5 contact-us" v-if="isButtonVisible">
    <Transition appear>
      <LinkButton
        label="Contact us"
        href="https://share.hsforms.com/19kcDaALnRUuT2y9J7SGGzg3prjk"
        target="_blank"
        style="display:"
        class="p-d-flex p-absolute p-button-rounded p-button-gradient p-right-0 p-bottom-0 p-align-center p-px-5 p-highlight p-button-inverted"
      >
        <icon data="@icon/conversations.svg" :fill="false" width="1.5em" height="1.5em" />
        <span class="p-ml-3 button-text p-text-uppercase">Feedback? Write us.</span>
      </LinkButton>
    </Transition>
  </div>
</template>
<script lang="ts">
import { ref } from "vue";
import LinkButton from "@/components/elements/LinkButton.vue";

export default {
  components: {
    LinkButton,
  },
  setup() {
    const showForm = ref(false);
    const showSuccess = ref(false);
    const isButtonVisible = ref(false);
    setTimeout(() => {
      isButtonVisible.value = true;
      setTimeout(() => {
        document.querySelector(".button-text")?.classList.add("hidden-text");

        const button = document.querySelector(".contact-us");
        button?.addEventListener("mouseover", () => {
          document.querySelector(".button-text")?.classList.remove("hidden-text");
        });

        button?.addEventListener("mouseout", () => {
          document.querySelector(".button-text")?.classList.add("hidden-text");
        });
      }, 5000);
    }, 2000);

    return { showForm, showSuccess, isButtonVisible };
  },
};
</script>
<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.p-text-semibold {
  font-weight: 600;
}

.contact-us {
  width: 25%;
  z-index: 10;
  @media (max-width: 768px) {
    width: 75%;
  }
  @media (max-width: 1280px) and (min-width: 768px) {
    width: 40%;
  }
  @media (max-width: 1660px) and (min-width: 1280px) {
    width: 30%;
  }
  @media (min-width: 1660px) {
    width: 20%;
  }
  .close-icon {
    cursor: pointer;
  }
  .button-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2px;
  }
  .p-button-gradient {
    font-weight: 500;
    letter-spacing: 1px;
    background-color: var(--card-bg);
    border: 0;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px;
      padding: 2px;
      background: linear-gradient(90deg, #fffa1e -0.13%, #ffa400 49.36%, #f346f7 99.89%);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
    &:hover {
      background: var(--hover-button-bg);
      color: var(--primary-color-text);
      border-color: var(--hover-button-bg);
    }
    &:focus {
      box-shadow: none;
    }
  }
  .hidden-text {
    width: 0;
    overflow: hidden;
    margin-left: 0 !important;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
}
</style>
